<template>
  <div v-click-outside="onBlur" class="v-phone-input-mts" :class="{'error': errorStatus}">
    <client-only>
      <vue-tel-input
        v-bind="phoneProps"
        v-model="currentValue"
        @focus="onFocus"
        @validate="updateValue"
        @country-changed="changeCountry"
      />
    </client-only>
    <span
      v-if="currentValue && isFocused"
      class="v-phone-input-mts__clear"
      @click="clearValue"
    ></span>
    <VErrorMTS v-if="errorStatus">{{getErrors && dirty ? getErrors : 'Укажите верный формат номера телефона'}}</VErrorMTS>
  </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'
import VErrorMTS from '~/components/Common/MTS/VErrorMTS.vue'

const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
    import(
      /* webpackChunkName: "chunk-vue-tel-input" */ '~/assets/scss/vue-tel-input-mts.scss'
    ),
  ]).then(([{ VueTelInput }]) => VueTelInput)

export default {
  name: 'VPhoneInputMTS',
  directives: { clickOutside },
  components: {
    VueTelInput,
    VErrorMTS,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    getErrors: {
      type: String,
      default: '',
    },
    dirty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: '',
      isFocused: false,
      validValue: '',
      phoneProps: {
        mode: 'international',
        defaultCountry: 'RU',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        validCharactersOnly : true,
        placeholder: '+7',
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['RU', 'BY', 'UZ', 'KG', 'TJ', 'UA', 'MD', 'MY', 'DE'],
        onlyCountries: [],
        ignoredCountries: [
          'AI',
          'AS',
          'AG',
          'BM',
          'VG',
          'KY',
          'GD',
          'GU',
          'JM',
          'BB',
          'VI',
          'TC',
          'TT',
          'VC',
          'LC',
          'KN',
          'MP',
          'MS',
        ],
        autocomplete: 'mobile tel',
        autoFocus: true,
        name: 'phone',
        id: 'phone',
        type: 'number',
        maxLen: 16,
        ref: 'phone',
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
          showFlags: false,
        },
        inputOptions: {
          showDialCode: true,
          maxlength: 15,
        },
      },
    }
  },
  computed: {
    errorStatus() {
      return this.currentValue != this.validValue && this.currentValue != '' && this.currentValue != '+7' || this.getErrors
    }
  },
  methods: {
    updateValue(telnumber) {
      if (telnumber) {
        if (telnumber.valid) {
          this.validValue = telnumber.number.international
        } else {
          this.validValue = ''
        }
        this.emitValue(this.validValue)
      }
    },
    changeCountry(country) {
      this.currentValue =
        this.validValue =
        this.phoneProps.placeholder =
          '+' + country.dialCode
    },
    emitValue(valueNumber) {
      this.$emit('input', valueNumber)
    },
    onFocus() {
      this.isFocused = true
      this.$emit('focus')
    },
    onBlur() {
      this.isFocused = false
    },
    clearValue() {
      this.currentValue = ''
      this.emitValue('')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-phone-input-mts {
  position: relative;

  &.error:after {
    content: '';
    position: absolute;
    right: 12px;
    top: calc(50% - 22px);
    display: block;
    background-image: url('~/assets/img/icons/mts/icon-mts--field-error.svg');
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    transition: 0.3s;
  }

  &.xl {
    ::v-deep(.vue-tel-input) {
      padding: 13px 12px;
    }
  }

  &__clear {
    position: absolute;
    right: 12px;
    top: calc(50% - 12px);
    background-image: url('~/assets/img/icons/mts/icon-mts--field-delete.svg');
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    transition: 0.3s;
    cursor: pointer;

    .error & {
      display: none;
    }
  }

  ::v-deep input {
    width: 100%;
    font-family: $font-mts-compact;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    outline: none;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-gray-1;
    }

    &:-moz-placeholder {
      color: $color-gray-1;
    }

    &::-moz-placeholder {
      color: $color-gray-1;
    }

    &:focus {
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder {
        color: #969fa8;
      }
    }
  }

  ::v-deep .vue-tel-input {
    border-radius: 6px;
    padding: 10px 12px;
    color: $color-dark-2;
    border: 1px solid $color-gray-3;
    background: $color-gray-2;
    transition: all 0.3s ease;

    &:not(:focus):hover {
      border: 1px solid $color-dark-2;
    }

    &:focus {
      border: 1px solid $color-blue;
    }
  }

  ::v-deep .vti__dropdown {
    position: static;
    padding: 0;
    font-family: $font-mts-compact;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    z-index: 10;
    /* Стили для основных элементов скроллбара */
    ::-webkit-scrollbar {
      width: 6px; /* Ширина вертикального скроллбара */
    }
    ::-webkit-scrollbar-thumb {
      background: $color-gray-2; /* Цвет ползунка */
      border-radius: 12px; /* Скругление углов ползунка */
    }
    ::-webkit-scrollbar-track {
      background: transparent; /* Цвет дорожки скролла */
    }
    ::-webkit-scrollbar-thumb:hover {
      background: $color-gray-3; /* Цвет ползунка при наведении */
    }

    &:hover {
      background: transparent;
    }

    &.open {
      .vti__dropdown-arrow {
        transform: scale(1.3) rotate(0deg);
      }
    }

    /* замена стрелкина кастомную по дс */
    &-arrow {
      color: transparent;
      width: 16px;
      height: 16px;
      background: url('~/assets/img/icons/mts/icon-mts--arrow-accordion.svg')
        no-repeat center / cover;
      pointer-events: none;
      transform: scale(1.3) rotate(180deg);
      transition: all 0.3s ease;
    }

    &-list {
      width: 100%;
      padding: 6px;
      top: auto;
      left: 0px;
      bottom: -8px;
      border-radius: 8px;
      border: none;
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12),
        0px 12px 20px 0px rgba(0, 0, 0, 0.14);
      transform: translateY(100%);
      strong {
        font-weight: 400;
      }
    }

    &-item {
      padding: 14px 6px;
      border-radius: 12px;
      border: none;
      transition: all 0.3s ease;
      &:hover {
        background: #bcc3d040;
      }
    }
  }
}
</style>
