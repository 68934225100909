export default {
  computed: {
    resultForm() {
      switch (this.typeMessage) {
        case 'P_ER_UNKNOWN':
          return {
            title: 'Не удалось завершить регистрацию',
            message: 'Обратиться в <a href="/faq_and_support">техподдержку</a>',
            img: {
              src: require('~/assets/img/form/form-fail.png'),
              width: '232',
              height: '175',
            },
            btn: {
              href: '/user',
              text: 'Попробовать еще раз',
            },
            error: true,
          }
        case 'P_REG_CONFIRM':
          return {
            title: 'Завершите регистрацию',
            message: `Подтвердите адрес электронной почты. Мы отправили письмо на&nbsp;${this.email}`,
            img: {
              src: require('~/assets/img/form/form-success-registration.png'),
              width: '232',
              height: '175',
            },
          }
        case 'P_REG_EXISTS':
          return {
            title: 'С возвращением',
            message: `Вы уже зарегистрированы.<br>
                    Через 5 секунд мы&nbsp;направим вас
                    на&nbsp;страницу входа`,
            img: {
              src: require('~/assets/img/form/form-success-registration.png'),
              width: '232',
              height: '175',
            },
          }
        case 'P_REGISTER_OK':
          return {
            title: 'Готово!',
            message: `Отправляем вас на&nbsp;страницу авторизации`,
            info: `Используйте логин ${this.email} и&nbsp;пароль, который придумали при&nbsp;регистрации`,
            img: {
              src: require('~/assets/img/form/form-success-registration.png'),
              width: '232',
              height: '175',
            },
          }
        case 'P_APPLICATION_OK':
        case 'ENQUEUED_OK':
          return {
            title: 'Заявка отправлена',
            message: this.applicationWithSend
              ? 'Запись вебинара откроется в новом окне и будет отправлена вам на почту!'
              : `Скоро наш менеджер свяжется с&nbsp;вами и&nbsp;подберет решение под&nbsp;ваши задачи`,
            img: {
              src: require('~/assets/img/form/form-success--application.png'),
              width: '232',
              height: '175',
            },
          }
        case 'P_APPLICATION_FAIL':
          return {
            title: 'Не удалось отправить заявку',
            message: `Попробуйте еще раз`,
            img: {
              src: require('~/assets/img/form/form-success--application.png'),
              width: '232',
              height: '175',
            },
          }
        default:
          return {
            title: 'Не удалось отправить данные',
            message: 'Обратиться в <a href="/faq_and_support">техподдержку</a>',
            img: {
              src: require('~/assets/img/form/form-fail.png'),
              width: '232',
              height: '175',
            },
            btn: {
              href: '/user',
              text: 'Попробовать еще раз',
            },
            error: true,
          }
      }
    },
  },
}
